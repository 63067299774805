<template>
    <div class="outer-wrapper">
        <el-carousel loop height="490px"  :autoplay="false">
            <el-carousel-item v-for="item in items" :key="item">
                <img class="img" :src="item" alt="" />
            </el-carousel-item>
        </el-carousel>
    </div>

</template>
<script>
    export default {
        name: "homeCarousel",
        data() {
            return {
                items: [process.env.BASE_URL + "img/banner1.jpg",
                process.env.BASE_URL + "img/banner2.jpg",
                process.env.BASE_URL + "img/banner3.jpg",
                process.env.BASE_URL + "img/banner4.jpg",
                ],
            }
        },
        methods: {

        },
    }
</script>
<style scoped>
    .outer-wrapper {
        margin: 0 auto;
        margin-bottom:0px;
        width: 1260px;
        text-align: center;
    }
    .img{
        width:100%;
        display: block;
    }
</style>